/* Dashboard layout */
.dashboard {
    display: flex;
    flex-direction: row;
    /* gap: 20px; */
    height: 100vh;
    /* Full height of the viewport */
    overflow: hidden;
    /* Prevents the entire frame from scrolling */
}

/* Sidebar and content adjustments */
.dashboard--content {
    background: #f5f1f1;
    flex: 1;
    /* border-radius: 20px; */
    /* padding: 1rem; */
    /* display: flex; */
    /* flex-direction: column; */
    overflow-y: auto;
    /* Ensures only the content area can scroll */
    height: 100%;
}

.dashboard--contentt {
    background: #f5f1f1;
    flex: 1;
    border-radius: 20px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* Ensures only the content area can scroll */
    height: 100%;
}

/* Header section */
.header {
    height: 40px;
    /* background-color: #ffffff;  */
    display: flex;
    align-items: center;
    padding: 0 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

/* Route section padding adjustments */
.route {
    /* padding: 1rem; */
    flex-grow: 1;
    overflow-y: auto;
    /* Allow table content or other dynamic data to scroll */
}

/* Responsive Styles */
@media (max-width: 1024px) {

    /* Tablet view */
    .dashboard {
        flex-direction: column;
        /* Stack sidebar and content vertically */
        gap: 0;
    }

    .dashboard--content {
        height: auto;
        /* padding: 1rem; */
    }
}

@media (max-width: 768px) {

    /* Phone view */
    .dashboard {
        flex-direction: column;
        /* Stack sidebar and content vertically */
        gap: 0;
    }

    .dashboard--content {
        /* padding: 0.5rem; */
    }

    .header {
        height: 40px;
        padding: 0 0.5rem;
    }

    /* .route {
        padding: 0.5rem;
    } */
}


.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
}