/* Grid and Box Styling */
.dashboard-container {
  padding: 20px;
  background-color: #f5f5f5;
}

/* Card Styling */
.dashboard-card {
  background-color: #32a852;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.dashboard-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.2);
}

/* Typography Styling */
.dashboard-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.dashboard-value {
  font-size: 1.25rem;
  color: #007bff;
}

/* Header Styling */
.attendance-header {
  border: 2px solid black;
  border-radius: 10px;
  text-align: center;
  background-color: #8abcf0;
  color: black;
  padding: 10px;
  font-size: 1.25rem;
  margin: 10px 0;
}

/* Chart Container */
.chart-container {
  margin-top: 20px;
}

/* Styles for calendar cells with attendance data */
.react-calendar__tile.present {
  background-color: #4eeb10 !important;
  border: 2px solid #4eeb10;
  border-radius: 30px;
  /* Light green for present */
}

.react-calendar__tile.absent {
  background-color: #f50b0b !important;
  border: 2px solid #f50b0b !important;
  border-radius: 30px;
  color: black;

  /* Light red for absent */
}

/* Default style for calendar cells without attendance data */
.react-calendar__tile {
  background-color: #fff;
  /* Default background for other days */
}


.react-calendar__tile.homework-yes {
  background-color: #4eeb10 !important;
  border: 2px solid #4eeb10;
  border-radius: 30px;
  /* Light green for present */
}

.react-calendar__tile.homework-no {
  background-color: #f50b0b !important;
  border: 2px solid #f50b0b !important;
  border-radius: 30px;
  color: black;

  /* Light red for absent */
}

/* Default style for calendar cells without attendance data */
.react-calendar__tile {
  background-color: #fff;
  /* Default background for other days */
}
 
