/*  
 .sidebar-container {
     position: relative;
 }

 .menu {
     width: 250px;
     height: 100vh;
     background-color: #282c34;
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 20px;
     box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
     transition: transform 0.3s ease-in-out;
     border-radius: 30px;
 }

 .closed .menu {
     transform: translateX(-250px); 
 }

 .open .menu {
     transform: translateX(0); 
 }

 .logo {
     display: flex;
     flex-direction: column;
     align-items: center;
     margin-bottom: 40px;
 }

 .logo-icon {
     font-size: 3rem;
     color: #ffffff;
 }

 h2 {
     color: #ffffff;
     margin-top: 10px;
 }

 .menu--list {
     flex-grow: 1;
     display: flex;
     flex-direction: column;
     align-items: center;
     width: 100%;
 }

 .item {
     display: flex;
     align-items: center;
     font-size: 1.2rem;
     color: #ffffff;
     padding: 10px 20px;
     width: 100%;
     text-align: left;
     text-decoration: none;
     border-radius: 8px;
     margin-bottom: 20px;
     transition: background-color 0.3s ease;
 }

 .item:hover {
     background-color: rgba(255, 255, 255, 0.1);
 }

 .icon {
     margin-right: 15px;
 }

 .logout {
     margin-top: auto;
     margin-bottom: 40px;
 }
 
 .toggle-btn {
     position: absolute;
     top: 20px;
     left: 10px;
     color: rgb(243, 234, 234);
     background-color: #080808;
     border: 2px solid black;
     border-radius: 10px;
     cursor: pointer;
     z-index: 1000; 
 }
 
 @media (max-width: 768px) {
     .sidebar-container {
         position: fixed;
         top: 0;
         left: 0;
         z-index: 1000; 
     }

     .menu {
         width: 250px;
         height: 100vh;
         position: absolute;
         left: 0;
         top: 0;
         transform: translateX(-250px); 
         transition: transform 0.3s ease-in-out;
     }

     .open .menu {
         transform: translateX(0); 
     }

     .menu--list {
         flex-direction: column;
     }

     .item {
         font-size: 1rem;
         padding: 15px;
         text-align: center;
     }

     .logout {
         margin-top: auto;
     }
 }

  */

/* Base styles for the sidebar */
.sidebar-container {
    position: relative;
}

.menu {
    width: 300px;
    height: 100vh;
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
    overflow-y: auto;
    /* Allow scrolling for smaller screens */
}

.closed .menu {
    transform: translateX(-250px);
    /* Hide sidebar when closed */
}

.open .menu {
    transform: translateX(0);
    /* Show sidebar when open */
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.logo-icon {
    font-size: 3rem;
    color: #ffffff;
}

h2 {
    color: #ffffff;
    margin-top: 10px;
    text-align: center;
}

.menu--list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.item {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #ffffff;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    text-decoration: none;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.icon {
    margin-right: 15px;
}

.logout {
    margin-top: auto;
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
}

/* Toggle button styles */
.toggle-btn {
    position: absolute;
    top: 20px;
    left: 10px;
    color: rgb(243, 234, 234);
    background-color: #080808;
    border: 2px solid black;
    border-radius: 10px;
    cursor: pointer;
    z-index: 1000;
    /* Ensure the toggle button is on top of everything */
}

/* Mobile and tablet responsive styles */
@media (max-width: 768px) {
    .sidebar-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        /* Ensure the sidebar is on top of everything */
    }

    .menu {
        width: 100%;
        max-width: 250px;
        /* Ensures the sidebar doesn't exceed the desired width */
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        transform: translateX(-100%);
        /* Initially hidden */
        transition: transform 0.3s ease-in-out;
        overflow-y: auto;
        /* Enable scrolling on small screens */
    }

    .open .menu {
        transform: translateX(0);
        /* Slide in from the left */
    }

    .menu--list {
        flex-direction: column;
    }

    .item {
        font-size: 1rem;
        padding: 10px;
        text-align: center;
    }

    .logout {
        margin-top: auto;
        margin-bottom: 100px;
    }
}

/* Styles for extra small devices like iPhones */
@media (max-width: 480px) {
    .item {
        font-size: 0.9rem;
        padding: 8px;
    }

    .logout {
        margin-bottom: 100px;
    }

    .logo {
        margin-bottom: 15px;
    }

    .logo-icon {
        width: 60px;
        height: 60px;
    }
}



.accordion-item {
    width: 100%;
    background-color: #000;
    /* Black background for accordion */
    color: #fff;
    /* White text */
    border-radius: 8px;
    margin-bottom: 10px;
}

.accordion-item .MuiAccordionSummary-root {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #282c34;
    /* Dark background for accordion header */
    color: #ffffff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* border-radius: 8px; */
}

.accordion-item .MuiAccordionSummary-root:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Hover background effect */
    color: inherit;
    /* Keeps the original text color (no change) */
}

.accordion-item .MuiAccordionDetails-root {
    background-color: #111;
    /* Darker background for dropdown */
    padding: 10px;
    color: #fff;
}

.accordion-item .dropdown-item {
    display: block;
    padding: 8px 20px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.accordion-item .dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    /* Background effect on hover */
    color: inherit;
    /* Keeps the original text color (no change) */
}

.accordion-item .MuiSvgIcon-root {
    color: #fff;
    /* White color for the expand/collapse icon */
}