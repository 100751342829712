.content {
    width: 75%;
    background: red;
}

.content--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center; 
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px; 
    /* background-color: #130e0e;  */
    position: sticky;
    top: 0;  
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
}

.header--title {
    color: #526d82;
    text-align: center;
    font-size: clamp(1.5rem, 2vw, 3rem);
    /* Adjusts between 1.5rem and 3rem based on viewport width */
    white-space: nowrap;
    /* Prevents the text from wrapping to the next line */
    overflow: hidden;
    /* Ensures content stays within the container */
    text-overflow: ellipsis;
    /* Adds ellipsis if the text overflows */
}


.header--activity {
    display: flex;
    align-items: center;
    gap: 20px;
}

.search-box {
    background-color: #dde6ed;
    padding: 8px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.search-box input {
    border: none;
    outline: none;
    background: transparent;
    padding: 5px;
    outline: none;
}